export enum ColumnType {
  COLLECTION_ACTIVITY = "COLLECTION_ACTIVITY",
  WALLETS_ACTIVITY = "WALLETS_ACTIVITY",
  COLLECTION_RANK = "COLLECTION_RANK",
  WALLET_RANK = "WALLET_RANK",
  PORTFOLIO = "PORTFOLIO",
  RUNES = "RUNES",
}

export enum CollectionRankType {
  Top = "Top",
  Trending = "Trending",
}

export enum ColumnWidth {
  SMALL = "Small",
  MEDIUM = "Medium",
  LARGE = "Large",
}

export enum WalletActivityFilter {
  BUY = "Buy",
  SELL = "Sell",
  MINT = "Mint",
  TRANSFER_TO = "Transfer to",
  TRANSFER_FROM = "Transfer from",
  BURN = "Burn",
  UNKNOWN = "Unknown",
}

export enum RANK_TIME_PERIOD {
  // "24h" = "24h",
  "1d" = "1d",
  "7d" = "7d",
  "30d" = "30d",
}

export const DAY_IN_SEC = 86400;

// do NOT change these. These values are synced with external payment service (Adapty)
export enum NinjalertsPlanNames {
  Free = "Free",
  Basic = "Basic",
  Pro = "Pro",
}

export enum DefaultWalletIconType {
  JAZZICON = "Jazzicon",
  BLOCKIE = "Blockie",
}

export enum FloorPriceCalculationType {
  LowestAsk = "lowest-ask",
  HighestBid = "highest-bid",
  AverageLastSales = "average-last-sales",
}

export enum ExchangeSymbols {
  ETH = "ETH",
  BTC = "BTC",
}

export enum AccessControlRoles {
  Admin = "Admin",
  User = "User",
}
